import {
  WYSIWYG_TOOLBAR_LINE_HEIGHT_OPTIONS,
  PARAGRAPH_MARGIN_OPTIONS
} from "@/helpers/constants";
import { Quill } from "@vueup/vue-quill";
import "quill-mention";

export interface EditorModule {
  name: string;
  module: unknown;
  options?: object;
}

const MentionBlot = Quill.import("blots/mention");

const tagGroups = [
  "business",
  "contact",
  "integration_partner",
  "personal_information"
];

export const getTagClass = (tag: string | undefined) => {
  const tagGroup = tagGroups.find((group) => tag?.includes(group));
  return tagGroup ? `quill-tag-${tagGroup}` : "quill-tag-other";
};

class StyledMentionBlot extends MentionBlot {
  static blotName = "styledMention";
  static render(data: { tag: string }) {
    const element = document.createElement("span");
    element.innerText = data.tag;
    element.classList.add(getTagClass(data.tag));
    return element;
  }
}

const Parchment = Quill.import("parchment");
const lineHeightConfig = {
  scope: Parchment.Scope.INLINE,
  whitelist: WYSIWYG_TOOLBAR_LINE_HEIGHT_OPTIONS
};
const lineHeightClass = new Parchment.Attributor.Class(
  "lineheight",
  "ql-line-height",
  lineHeightConfig
);
const lineHeightStyle = new Parchment.Attributor.Style(
  "lineheight",
  "line-height",
  lineHeightConfig
);

const marginConfig = {
  scope: Parchment.Scope.BLOCK,
  whitelist: PARAGRAPH_MARGIN_OPTIONS
};

const marginBottomClass = new Parchment.Attributor.Class(
  "marginBottom",
  "ql-margin-bottom",
  marginConfig
);
const marginBottomStyle = new Parchment.Attributor.Style(
  "marginBottom",
  "margin-bottom",
  marginConfig
);

const marginTopClass = new Parchment.Attributor.Class(
  "marginTop",
  "ql-margin-top",
  marginConfig
);
const marginTopStyle = new Parchment.Attributor.Style(
  "marginTop",
  "margin-top",
  marginConfig
);

[
  lineHeightClass,
  lineHeightStyle,
  marginBottomClass,
  marginBottomStyle,
  marginTopClass,
  marginTopStyle
].forEach((attributor) => Parchment.register(attributor));

export { StyledMentionBlot };
